* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.awssld__bullets {
    display: none !important;
}

.awssld__wrapper .awssld__content img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Use 'cover' if you want the image to cover the entire area */
    background-color: transparent;
}
