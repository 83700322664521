.ql-container {
    max-height: 70vh; /* Increased max-height for more writing space */
    min-height: 20vh;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    max-height: 60vh; /* Increased max-height for more writing space */
    overflow-y: auto;
    overflow-x: hidden;
}

.richtextWrap {
    position: relative; /* Removed quotes around 'relative' */

    :global(.ql-editor) {
        /* fix the default theme color */
        blockquote,
        div,
        h2,
        h3,
        h4,
        ol,
        p,
        ul {
            color: black; /* Adjusted to use a single color value */
        }
    }
}
